const environment: {[key: string]: string | undefined} = import.meta.env;

export const initEnvironmentVariables = async (): Promise<void> => {
	if (import.meta.env.MODE !== 'test') {
		const runtimeEnv = (await fetch('/env.json').then((r) => r.json())) as Record<string, string>;
		for (const k in runtimeEnv) {
			environment[k] = runtimeEnv[k];
		}
	}
	return Promise.resolve();
};

export const env = (key: string, fallback?: string): string => {
	const value = environment[key];
	if (value !== undefined) {
		return value;
	} else if (fallback !== undefined) {
		return fallback;
	} else {
		throw new Error(`missing environment variable: "${key}"`);
	}
};

export const envInt = (key: string, fallback?: number): number => {
	const value = env(key, fallback?.toString());
	return Number.parseInt(value, 10);
};

export const envBool = (key: string, fallback?: boolean): boolean => {
	const value = env(key, fallback?.toString());
	return value === 'true';
};

export type Feature =
	| 'LICENSE_CHECKS'
	| 'EFFICIENCY_MONITORING'
	| 'ASSET_ONBOARDING'
	| 'BACKUP'
	| 'BACKUP_CREATION'
	| 'BACKUPS_SCHEDULE_CREATION'
	| 'CHECK_ASSET_CONNECTION_FOR_BACKUP_CREATION'
	| 'CHECK_KDC_VERSION_FOR_BACKUP_CREATION'
	| 'CHECK_ASSET_CONNECTION_FOR_KRCDIAG_CREATION'
	| 'MAINTENANCE_NOTIFICATIONS'
	| 'CHANGELOG_NOTIFICATIONS';

export function isFeatureEnabled(feature: Feature): boolean {
	const disabledFeatures = env('VITE_DISABLED_FEATURES', '')
		.split(',')
		.map((f) => f.trim());

	const tenantId = env('VITE_KEYCLOAK_REALM');

	return !disabledFeatures.includes(feature) && !disabledFeatures.includes(`${feature}_${tenantId}`);
}

export const isCloudEnvironment = (): boolean => envBool('VITE_PLATFORM_IOTHUB_ENABLED');

export const isOpcuaEnabled = (): boolean => envBool('VITE_PLATFORM_OPCUA_ENABLED');
